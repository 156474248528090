"use client";

import { ssoInit } from "@/packages/api/resources/v1/sso";
import { applicationInsights } from "@/packages/azure/ApplicationInsights";
import { Button } from "@/packages/ui/Button";
import { useFloatingUIStore } from "@/packages/ui/floatingUI.store";
import { useState } from "react";

export function SsoLoginButton() {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useFloatingUIStore();

  const handleLogin = async () => {
    setLoading(true);

    try {
      const response = await ssoInit();

      if (!response.success) {
        setAlert({
          title: 'Error!',
          content: <p>Failed to initiate SSO login</p>,
        });

        applicationInsights.trackException({ error: new Error('Failed to initiate SSO login') });
        return;
      }

      window.location.href = response.data.authUrl;
    } catch (error: any) {
      applicationInsights.trackException({ error });
      setLoading(false);
      setAlert({
        title: 'Error!',
        content: <p>{error}</p>,
      });
    }
  };

  return (
    <Button className="" onClick={handleLogin} variant="primary" label={loading ? "Redirecting..." : "Sign in with Okta"} disabled={loading} />
  );
}